//https://mae.chab.in/archives/59690
/** props: fix:fixed or not, off:normal or not, top:top position, zindex, child component */
const ScrollFix = (props: any) => {
  return (
    <div
      style={{
        position: `${props.fix ? "fixed" : props.off ? "static" : "sticky"}`,
        //position: "-webkit-sticky",
        top: `${props.top ? props.top : 60}px`,
        zIndex: `${props.zindex ? props.zindex : "13"}`,
      }}
      className="bg-white dark:bg-gray-800 "
    >
      {props.children}
    </div>
  );
};
export default ScrollFix;

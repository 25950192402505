import Head from "next/head";
import { useRouter } from "next/router";
import { getLocale } from "@/lang/get-locale";

const { t } = getLocale();
const siteTitle = "Commutty IT";
const blogDescription = t.SERVICEDESCRIPTION;
const facebookAppId = "975259896577593";
const blogAuthorTwitterUserName = "Commutty_IT";

const OGPHead = ({ isRoot, title, description, imageUrl, cardType }: any) => {
  const router = useRouter();
  const type = isRoot ? "website" : "article";
  const blogImageUrl = process.env.FRONTEND_URL + (isRoot ? "home.jpg" : "bg.jpg");
  return (
    <Head>
      <title>{title || siteTitle}</title>
      <meta name="description" content={description || blogDescription} />
      <meta property="og:title" content={title || siteTitle} />
      <meta
        property="og:description"
        content={description || blogDescription}
      />
      <meta property="og:url" content={"https://it.commutty.com" + router.asPath} />
      <meta property="og:type" content={type} />
      <meta property="og:site_name" content={siteTitle} />
      <meta property="og:image" content={imageUrl || blogImageUrl} />
      <meta property="fb:app_id" content={facebookAppId} />

      <meta name="twitter:card" content={cardType || "summary_large_image"} />
      <meta name="twitter:site" content={`@${blogAuthorTwitterUserName}`} />
    </Head>
  );
};

export default OGPHead;

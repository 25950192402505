import { getLocale } from "@/lang/get-locale";

const GetDateAgo = (date: any) => {
  const { t } = getLocale();
  var now: any = new Date();
  var ago = now - Date.parse(date); //どれくらい前か

  if (Math.floor(ago / (1000 * 60 * 60 * 24 * 30))) {
    return (
      <>
        {Math.floor(ago / (1000 * 60 * 60 * 24 * 30))}
        {t.MONTHAGO}
      </>
    );
  } else if (Math.floor(ago / (1000 * 60 * 60 * 24))) {
    return (
      <>
        {Math.floor(ago / (1000 * 60 * 60 * 24))}
        {t.DAYSAGO}
      </>
    );
  } else if (Math.floor(ago / (1000 * 60 * 60))) {
    return (
      <>
        {Math.floor(ago / (1000 * 60 * 60))}
        {t.HOURSAGO}
      </>
    );
  } else {
    return <>{t.NOW}</>;
  }
};

export default GetDateAgo;

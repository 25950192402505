import { Delete, Edit, LocalOffer } from "@mui/icons-material";
import { Button, CardContent, Dialog } from "@mui/material";
import axios from "axios";
import { auth } from "@/utils/firebase";
import Link from "next/link";
import React, { useState } from "react";
import Avatar from "./Avatar";
import { getLocale } from "@/lang/get-locale";
import GetTimeAgo from "./GetTimeAgo";
import { getRandomImage } from "./RandomeImage";
import { useRouter } from "next/router";

export default function RecipeReviewCard({
  data,
  editable,
  deleteHandler,
}: any) {
  const router = useRouter();
  const { t } = getLocale();
  const authorName = data.author.username;
  const authorSlug = data.author.slug;
  const authorImg = (data.author.minicdn || data.author.cdn)?.replace(
    "cdn.apollon.ai",
    "cdn.magicode.io"
  );
  const title = data.title;
  const description = data.description;
  const link = `/${authorSlug}/articles/${data.slug}`;
  const inBox = !!data.box;
  const src = inBox
    ? data.box.cdn || getRandomImage(data.box.slug)
    : data.cdnmini || getRandomImage(data.slug);
  const created_at = data.published_at || data.created_at;
  const noteSlug = data.slug;
  const likeCount = data.like_count;
  const is_open = data.is_open;
  const [deleteModal, ToggleDeleteModal] = useState<any>(false);
  const [deleteArticleID, setDeleteArticle] = useState<any>();
  const handleModal = () => {
    ToggleDeleteModal(false);
  };
  const handleDelete = (slug: any) => {
    ToggleDeleteModal(true);
    setDeleteArticle(slug);
  };
  const sendDelete = (slug: any) => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        user
          .getIdToken(true)
          .then((idToken) => {
            const url = `${process.env.BACKEND_URL}/v1/notebox/detail/${slug}`;
            axios
              .delete(url, {
                headers: {
                  "content-type": "application/json",
                  Authorization: `JWT ${idToken}`,
                },
              })
              .then((res) => {
                ToggleDeleteModal(false);
                deleteHandler && deleteHandler();
              })
              .catch((error) => {
                console.log(error);
              });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };
  const mainTags = data.tag.length > 3 ? data.tag.slice(0, 3) : data.tag;
  return <>
    <div className="relative group flex py-6 w-full">
      <div className="grow pr-2">
        <div className="pb-1 px-2 relative">
          <span className="flex items-center">
            <Link scroll={false} href={"/" + authorSlug}>

              <Avatar size={21} name={authorName} src={authorImg} />

            </Link>

            <div className="px-2">
              <span className="omit-1">
                <Link scroll={false} href={"/" + authorSlug} className="text-xs">
                  {authorName}
                </Link>
              </span>
            </div>
            {editable && (
              <>
                <div>
                  ・
                  <span
                    className="px-1 cursor-pointer"
                    onClick={() => {
                      router.push(`/articles/${noteSlug}/edit`);
                    }}
                  >
                    <Edit className="text-gray-500 text-base" />
                  </span>
                  ・
                  <span
                    className="px-1 cursor-pointer"
                    onClick={() => {
                      handleDelete(noteSlug);
                    }}
                  >
                    <Delete className="text-gray-500 text-base" />
                  </span>
                </div>
              </>
            )}
          </span>
        </div>
        <div>
          <Link
            href={
              editable && !is_open ? `/articles/${noteSlug}/preview` : link
            }
          >

            <CardContent style={{ padding: "3px", paddingLeft: "8px" }}>
              <div>
                <b className="text-base md:text-xl break-all">{title}</b>
              </div>
            </CardContent>

          </Link>
        </div>
        <Link
          href={editable && !is_open ? `/articles/${noteSlug}/preview` : link}
        >

          <div className="px-2 text-gray-500 dark:text-gray-300 hidden md:block text-sm break-all">
            {description}
          </div>{" "}

        </Link>
        <div className="px-2 relative">
          <span className="flex items-center">
            <div>
              <div>
                <span className="text-gray-500 text-xs">
                  <time dateTime={created_at}>{GetTimeAgo(created_at)}</time>
                </span>

                <span className="text-gray-500 text-xs ">
                  ・{data.read_length} min read
                </span>
                {likeCount > 0 && (
                  <span className="text-gray-500 text-xs">
                    ・{likeCount} likes
                  </span>
                )}
              </div>
            </div>
          </span>
        </div>

        <div
          style={{
            fontSize: "10px",
          }}
          className="text-gray-500 px-2 pt-2"
        >
          {mainTags.map((tag: any, idx) => {
            return (
              <span key={tag.name} className="px-1">
                {idx == 0 && (
                  <span className="pr-1">
                    <LocalOffer style={{ height: "13px", width: "13px" }} />
                  </span>
                )}

                <span>
                  <Link href={`/tags/${tag.name}`}>
                    {tag.name}
                  </Link>
                </span>
              </span>
            );
          })}
        </div>
      </div>
      <div>
        <div>
          <Link
            scroll={false}
            href={
              editable && !is_open ? `/articles/${noteSlug}/preview` : link
            }
          >

            <div
              style={{
                backgroundImage: `url(${src})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "50% 50%",
              }}
              className="h-24 w-24 md:h-36 md:w-48"
            ></div>

          </Link>
        </div>
      </div>
    </div>

    {editable && (
      <Dialog
        onClose={handleModal}
        open={deleteModal}
        className="text-center"
      >
        <div style={{ padding: "24px" }}>
          <div className="py-3">
            <h4>
              <b style={{ color: "red" }}>{t.PARMANENTLYDELETE}</b>
            </h4>
          </div>

          <hr />

          <div className="py-3">
            <span className="px-2">
              <Button
                onClick={() => {
                  ToggleDeleteModal(false);
                }}
              >
                <b>{t.CANCEL}</b>
              </Button>
            </span>

            <span className="px-2">
              <Button
                onClick={() => {
                  sendDelete(deleteArticleID);
                }}
                style={{ color: "red" }}
              >
                <b>{t.DELETE}</b>
              </Button>
            </span>
          </div>
        </div>
      </Dialog>
    )}
  </>;
}
